export default async (el, opts) => {
  const Flickity = await import('flickity').then(m => m.default)
  el.classList.add('carousel-ready')
  const carousel = new Flickity(el, {
    autoPlay: 8000
  })

  return {
    carousel
  }
}
