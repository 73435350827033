import { on } from 'luett'
import Modernizr from 'modernizr'
import throttle from 'lodash.throttle'
import Point from '../models/point'

function MovingObject (el, adapter, radius) {
  return {
    rerender: ratios => adapter(radius, ratios),
    radius
  }
}

MovingObject.adapters = {
  translate: el => {
    // todo unimplemented
    return () => {}
  },
  background: el => {
    const style = window.getComputedStyle(el)
    const backgroundPosition = (style.backgroundPosition || '0% 0%').split(' ')
    const bgPos = {
      x: backgroundPosition[0],
      y: backgroundPosition[1]
    }

    return (radius, ratios) => {
      const pos = {
        x: radius * ratios.x,
        y: radius * ratios.y
      }
      const newBgPos = `calc(${bgPos.x} + ${pos.x}px) calc(${bgPos.y} + ${pos.y}px)`
      el.style.backgroundPosition = newBgPos
    }
  }
}

function rerender () {
  if (!lastPosition) return

  const ratios = {
    x: lastPosition.x / screenWidth * 2 - 1,
    y: lastPosition.y / screenHeight * 2 - 1
  }
  objects.forEach(object => object.rerender(ratios))
}

const objects = []
let screenWidth = window.outerWidth
let screenHeight = window.outerHeight
let lastPosition = null

on(window, 'resize', throttle(e => {
  screenWidth = window.outerWidth
  screenHeight = window.outerHeight
  rerender()
}, 100))

on(document, 'mousemove', throttle(e => {
  lastPosition = Point.fromClientEvent(e)
  rerender()
}, 15))

export default (el, opts) => {
  if (Modernizr.svgclippaths && Modernizr.backgroundblendmode) {
    const adapter = MovingObject.adapters[opts.conf.property || 'translate'](el)
    const radius = parseInt(opts.conf.radius, 10)
    objects.push(MovingObject(el, adapter, radius))
  }
}
