function Point (x, y) {
  function distanceTo (point, axis = null) {
    if (axis === null) {
      return Math.sqrt(Math.pow(point.x - x) + Math.pow(point.y - y))
    } else if (axis === 'x') {
      return Math.abs(point.x - x)
    } else if (axis === 'y') {
      return Math.abs(point.y - y)
    } else {
      throw new Error('unknown axis')
    }
  }

  return { x, y, distanceTo }
}

Point.fromScreenEvent = e => Point(e.screenX, e.screenY)
Point.fromClientEvent = e => Point(e.clientX, e.clientY)

export default Point
