import './css/third-party/bulma.scss'
import './css/revoltmag.less'

// polyfills and loaders
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import loadPolyfills from './polyfills'

// default imports
import { component } from 'luett'

// transforms & components
import carousel from './transforms/carousel'
import inlineGallery from './transforms/inline-gallery'
import lightbox from './transforms/lightbox'
import masonry from './transforms/masonry'
import movement from './transforms/movement'
import categoryBackdrop from './transforms/category-backdrop'
import tagSelect from './transforms/tag-select'
import autosize from './transforms/autosize'
import share from './transforms/share'
import navbar from './transforms/navbar'

function init (searchIn = window.document) {
  const globals = {
    root: searchIn
  }

  component('carousel', carousel, globals)
  component('inline-gallery', inlineGallery, globals)
  component('lightbox', lightbox, globals)
  component('masonry', masonry, globals)
  component('movement', movement, globals)
  component('category-backdrop', categoryBackdrop, globals)
  component('autosize', autosize, globals)
  component('tag-select', tagSelect, globals)
  component('share', share, globals)
  component('navbar', navbar, globals)
}

loadPolyfills().then(() => init())
