import imagesLoaded from 'imagesloaded'

export default (el, opts) => {
  function init (Masonry) {
    return new Masonry(el, {
      itemSelector: opts.conf.itemSelector
    })
  }

  import('masonry-layout')
    .then(Masonry => {
      imagesLoaded(el, () => init(Masonry))
    })
}
