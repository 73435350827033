import delegate from 'delegate'
import { $ } from 'luett'
import Lightbox from './lightbox'

export default async (el, opts) => {
  const gallery = $('[data-gallery]', el)
  const lightbox = await Lightbox(gallery, { conf: { clickListener: false } })
  const Flickity = await import('flickity')
  const flickity = new Flickity(gallery, {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false
  })
  const showLightbox = (event, pointer, cellElement, cellIndex) => {
    event.preventDefault()
    event.stopPropagation()
    lightbox.show(cellIndex)
  }

  flickity.on('staticClick', showLightbox)
  delegate(el, 'a', 'click', e => {
    e.preventDefault()
  })
}
