import { on, $$ } from 'luett'
import imagesLoaded from 'imagesloaded'
import Modernizr from 'modernizr'
import throttle from 'lodash.throttle'
import Point from '../models/point'

const primaryColor = '#99020b'

const data = {
  areListenersRegistered: false,
  objects: []
}

function windowPosition () {
  return {
    x: window.pageXOffset || window.scrollX,
    y: window.pageYOffset || window.scrollY
  }
}

function getCategoryColorMap () {
  const rootStyle = window.getComputedStyle(document.documentElement)
  const categoryNames = rootStyle.getPropertyValue('--category-names').trim().split(',')
  const result = {}
  for (const name of categoryNames) {
    result[name] = rootStyle.getPropertyValue(`--category-color-${name}`)
  }
  return result
}

function recalculatePositions () {
  const windowPos = windowPosition()
  const documentHeight = document.documentElement.getBoundingClientRect().height
  const colors = getCategoryColorMap()
  const categories = $$('[data-category]').map(el => {
    const rect = el.getBoundingClientRect()
    return {
      name: el.dataset.category,
      width: rect.width,
      height: rect.height,
      pos: Point(
        rect.left + windowPos.x,
        rect.top + windowPos.y
      )
    }
  })

  // make sure categories are ordered in occurrence on the page
  categories.sort((a, b) => {
    if (a.pos.y > b.pos.y) return 1
    if (a.pos.y < b.pos.y) return -1
    return 0
  })

  const stops = [
    [primaryColor, '0px']
  ]
  for (const category of categories) {
    stops.push(
      [primaryColor, `${category.pos.y}px`],
      [colors[category.name], `${category.pos.y}px`],
      [colors[category.name], `${category.pos.y + category.height}px`],
      [primaryColor, `${category.pos.y + category.height}px`]
    )
  }
  stops.push(
    [primaryColor, `${documentHeight}px`]
  )
  const gradient = `linear-gradient(${stops.map(stop => {
    return `${stop[0]} ${stop[1]}`
  }).join(',')})`

  Object.assign(data, {
    documentHeight,
    gradient
  })
}

function reassignStyle () {
  for (const object of data.objects) {
    object.style.backgroundImage = data.gradient
    object.style.backgroundColor = 'transparent'
    object.style.backgroundSize = `100% ${data.documentHeight}px`
  }
}

function rerender () {
  const windowPos = windowPosition()

  for (const object of data.objects) {
    object.style.backgroundPosition = `0 -${windowPos.y + 5}px`
  }
}

function registerListeners () {
  if (data.areListenersRegistered) return

  recalculatePositions()
  on(window, 'scroll', throttle(e => {
    rerender()
  }, 15), { capture: false, passive: true })
  on(window, 'resize', throttle(e => {
    recalculatePositions()
    rerender()
  }, 100))
  data.areListenersRegistered = true
}

export default el => {
  if (Modernizr.svgclippaths && Modernizr.backgroundblendmode) {
    imagesLoaded(document.documentElement, () => {
      registerListeners()
      data.objects.push(el)
      reassignStyle()
      rerender()
    })
  }
}
