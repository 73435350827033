import bowser from 'bowser'
import { on } from 'luett'
import shareApple from '../../revoltmag/modules/core/icons/light/share.svg'
import shareDefault from '../../revoltmag/modules/core/icons/light/share-alt.svg'

export default async (el, opts) => {
  if (typeof navigator.share === 'undefined') return

  const payload = JSON.parse(opts.conf.payload)
  const shareIcon = await window.fetch(bowser.ios ? shareApple : shareDefault).then(r => r.text())

  el.classList.add(`share-default-${bowser.ios ? 'ios' : 'alt'}`)
  el.innerHTML = shareIcon
  el.children[0].classList.add('svg-inline--fa', 'fa-fw')

  on(el, 'click', e => {
    navigator.share(payload).catch(() => {})
  })
}
