export default async (el, opts) => {
  const Choices = await import('choices.js')
  const choices = new Choices(el, {
    removeItemButton: true,
    noResultsText: 'Kein passendes Tag gefunden',
    noChoicesText: 'Keine Tags zur Auswahl',
    itemSelectText: 'Hiernach suchen'
  })
  return choices
}
