export default el => {
  const handleEntry = entries => {
    const isStaticVisible = entries[0].boundingClientRect.y > 0
    document.documentElement.classList.toggle('navbar-show', !isStaticVisible)
  }

  const io = new window.IntersectionObserver(handleEntry, {
    threshold: [0, 1],
    root: null
  })

  io.observe(el)

  return {
    destroy () {
      io.disconnect()
    }
  }
}
